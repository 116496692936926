//React libraries
import { useState } from "react";
//import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

//Typescript types
//import type { RootState } from "../store";
import { ROUTES } from "./routerConfig";
import React from "react";

function PrivateRoute() {
  //Use state
  // const [isAuthenticated] = useState(useSelector((state: RootState) => state.authentication.isAuthenticated));

  return <Outlet />;
}

export default PrivateRoute;
