import React from "react";
import {
  AcademicCapIcon,
  CheckCircleIcon,
  HandRaisedIcon,
  RocketLaunchIcon,
  SparklesIcon,
  SunIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
const stats = [
  { label: "Année de création", value: "2012" },
  { label: "Membres de l'équipe", value: "120+" },
  { label: "Utilisateurs sur la plateforme", value: "250k" },
  { label: "Montant versé aux créateurs", value: "€70M" },
];

const values = [
  {
    name: "Être exceptionnel.",
    description:
      "Nous visons l'excellence en offrant des outils de DJ de classe mondiale et un support inégalé.",
    icon: RocketLaunchIcon,
  },
  {
    name: "Prendre des responsabilités.",
    description:
      "Nous nous engageons à vous fournir les meilleures ressources pour que vous puissiez briller sur scène.",
    icon: HandRaisedIcon,
  },
  {
    name: "Être solidaire.",
    description:
      "Notre communauté de DJs est là pour s'entraider et grandir ensemble dans l'univers de la musique.",
    icon: UserGroupIcon,
  },
  {
    name: "Toujours apprendre.",
    description:
      "Nous croyons en l'apprentissage continu pour rester à la pointe des dernières tendances et techniques DJ.",
    icon: AcademicCapIcon,
  },
  {
    name: "Partager vos connaissances.",
    description:
      "Nous encourageons le partage d'expérience et de connaissances pour inspirer et élever les autres DJs.",
    icon: SparklesIcon,
  },
  {
    name: "Profiter des moments de détente.",
    description:
      "Nous savons que la créativité naît aussi du repos, c'est pourquoi nous valorisons les moments de détente.",
    icon: SunIcon,
  },
];

const team = [
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    location: "Toronto, Canada",
  },
  {
    name: "Michael Foster",
    role: "Co-Founder / CTO",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    location: "TGlasgow, Scotland",
  },
  {
    name: "Dries Vincent",
    role: "Business Relations",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    location: "Niagara Falls, Canada",
  },
];
const benefits = [
  "Salaires compétitifs",
  "Horaires de travail flexibles",
  "30 jours de vacances payées",
  "Retraites d'équipe annuelles",
  "Avantages pour vous et votre famille",
  "Un environnement de travail stimulant",
  "Accès à des équipements DJ de pointe",
  "Opportunités de jouer dans des événements exclusifs",
  "Formation continue et développement des compétences",
];

const AboutView = () => {
  return (
    <main className="relative isolate bg-gray-900">
      {/* Background */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-4 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
        />
      </div>

      {/* Header section */}
      <div className="px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl pt-24 text-center sm:pt-40">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Nous aimons les DJs
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Vivez l'expérience ultime de la musique avec nos services dédiés aux
            DJs. Créez, mixez, et partagez vos sons avec le monde entier.
          </p>
        </div>
      </div>

      {/* Content section */}
      <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
            <div>
              <p>
                Plongez dans l'univers du mixage musical avec nos outils de
                pointe. Que vous soyez un DJ débutant ou expérimenté, nos
                services vous aideront à créer des sets mémorables. Notre
                interface intuitive et nos fonctionnalités avancées vous
                permettent de personnaliser chaque aspect de votre musique.
              </p>
              <p className="mt-8">
                Découvrez des rythmes nouveaux et expérimentez avec des sons
                uniques. Nos ressources éducatives et notre communauté dynamique
                vous accompagneront à chaque étape de votre parcours musical.
                Rejoignez-nous et faites vibrer le monde avec vos mixages.
              </p>
            </div>
            <div>
              <p>
                Profitez de notre bibliothèque de sons diversifiée et de nos
                outils d'analyse avancés pour affiner vos performances. Que ce
                soit pour des événements live ou des enregistrements en studio,
                notre plateforme s'adapte à tous vos besoins. Explorez de
                nouvelles possibilités musicales et laissez libre cours à votre
                créativité.
              </p>
              <p className="mt-8">
                Avec notre support 24/7 et nos mises à jour régulières, restez à
                la pointe de la technologie musicale. Notre mission est de vous
                offrir la meilleure expérience possible, afin que vous puissiez
                vous concentrer sur ce que vous faites de mieux : faire danser
                le monde entier.
              </p>
            </div>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
            {stats.map((stat, statIdx) => (
              <div
                key={statIdx}
                className="flex flex-col-reverse gap-y-3 border-l border-white/20 pl-6"
              >
                <dt className="text-base leading-7 text-gray-300">
                  {stat.label}
                </dt>
                <dd className="text-3xl font-semibold tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      {/* Image section */}
      <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
        <img
          alt=""
          src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2894&q=80"
          className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
        />
      </div>

      {/* Values section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Nos valeurs
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Nos valeurs définissent qui nous sommes en tant que DJs et ce que
            nous apportons à chaque performance. Découvrez ce qui nous anime et
            nous inspire.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {values.map((value) => (
            <div key={value.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <value.icon
                  aria-hidden="true"
                  className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                />
                {value.name}
              </dt>{" "}
              <dd className="inline">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>

      {/* Team section */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Notre équipe
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Rencontrez notre équipe de DJs talentueux et passionnés. Nous sommes
            dédiés à offrir des expériences musicales inoubliables.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
        >
          {team.map((person) => (
            <li key={person.name}>
              <img
                alt=""
                src={person.imageUrl}
                className="aspect-[14/13] w-full rounded-2xl object-cover"
              />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">
                {person.name}
              </h3>
              <p className="text-base leading-7 text-gray-300">{person.role}</p>
              <p className="text-sm leading-6 text-gray-500">
                {person.location}
              </p>
            </li>
          ))}
        </ul>
      </div>

      {/* CTA section */}
      <div className="relative isolate -z-10 mt-32 sm:mt-40">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
              className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Rejoignez notre équipe
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Vous êtes passionné par la musique et le DJing ? Rejoignez notre
                équipe de professionnels et partagez votre talent avec le monde.
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-7 w-5 flex-none"
                    />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10 flex">
                <a
                  href="#"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Voir nos offres d'emploi{" "}
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
        >
          <div
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
          />
        </div>
      </div>
    </main>
  );
};

export default AboutView;
