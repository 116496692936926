import React from 'react';
import AboutView from '../components/aboutView';

const About = () => {
  return (
    <AboutView/>
  );
};

export default About;
