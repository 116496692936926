import { BrowserRouter } from "react-router-dom";
//Routing
import Routing from "./routes/routing";
import Header from "./components/header/header";
import Footer from "./components/footer/footex";

function App() {
  return (
    <BrowserRouter basename="/">
      <Header />
      <div>
        <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-[#F8F7F5] text-black">
          <>
            <div className={"h-full"}>
              <Routing />

              <Footer />
            </div>
          </>
        </div>
      </div>
    </BrowserRouter>
  );
}
export default App;
