//React libraries
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

//Pages
import Home from "../pages/home";
import About from "../pages/about";
import NotFoundPage from "../pages/notFoundPage";

//Routes
import { ROUTES } from "./routerConfig";
import React from "react";

function Routing() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<PrivateRoute />}>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
      </Route>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.ABOUT} element={<About />} />
      {/* Add the catch-all route for 404 page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default Routing;
