import React from "react";
import HomeView from "../components/homeView";

const Home = () => {
  return (
    <HomeView/>
  );
};

export default Home;
